<script lang="ts">
    import XIcon from '@/Icons/social_icons/xIcon.svelte';
    import FacebookIcon from '@/Icons/social_icons/facebookIcon.svelte';
    import DiscordIcon from '@/Icons/social_icons/discordIcon.svelte';
    import InstagramIcon from '@/Icons/social_icons/instagramIcon.svelte';
    import TelegramIcon from '@/Icons/social_icons/telegramIcon.svelte';
    import TiktokIcon from '@/Icons/social_icons/tiktokIcon.svelte';
    import WhatsappIcon from '@/Icons/social_icons/whatsappIcon.svelte';
    import YoutubeIcon from '@/Icons/social_icons/youtubeIcon.svelte';

    const socialMediaUrls = {
        facebook: '',
        twitter: 'https://x.com/BuyMyNudesHQ',
        instagram: '',
        whatsapp: '',
        tiktok: '',
        youtube: '',
        telegram: '',
        discord: 'https://discord.gg/9FDuNsCUVP',
        reddit: '',
    };

    let currentYear = new Date().getFullYear();
    let theme = 'light'; // Default theme
    let blendMode = '';

    // Function to detect the theme based on the presence of the "dark" class
    function detectTheme() {
        const rootElement = document.documentElement;
        theme = rootElement.classList.contains('dark') ? 'dark' : 'light';
    }

    // Reactive statement to update blendMode based on theme
    $: {
        detectTheme();
        blendMode = theme === 'light' ? 'mix-blend-multiply' : '';
    }
</script>

<footer class="footer mt-4 w-full py-2  dark:bg-neutral-800 bg-neutral-200" >
    <div class="flex-row-reverse items-center justify-around md:flex">
        <div class="flex items-center">
            <div class="mx-auto mb-2 mt-2 flex justify-center space-x-10 text-center">
                <div class="flex items-center justify-center">
                    {#if socialMediaUrls.discord}
                        <a class="m-2" href="{socialMediaUrls.discord}" target="_blank" rel="noopener noreferrer">
                            <DiscordIcon className="size-4 text-gray-500" />
                        </a>
                    {/if}
                    {#if socialMediaUrls.twitter}
                        <a
                            class="m-2 size-4 text-gray-500"
                            href="{socialMediaUrls.twitter}"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <XIcon className="size-4 text-gray-500" />
                        </a>
                    {/if}
                    {#if socialMediaUrls.facebook}
                        <a class="m-2" href="{socialMediaUrls.facebook}" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon />
                        </a>
                    {/if}
                    {#if socialMediaUrls.instagram}
                        <a class="m-2" href="{socialMediaUrls.instagram}" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon />
                        </a>
                    {/if}
                    {#if socialMediaUrls.whatsapp}
                        <a class="m-2" href="{socialMediaUrls.whatsapp}" target="_blank" rel="noopener noreferrer">
                            <WhatsappIcon />
                        </a>
                    {/if}
                    {#if socialMediaUrls.tiktok}
                        <a class="m-2" href="{socialMediaUrls.tiktok}" target="_blank" rel="noopener noreferrer">
                            <TiktokIcon />
                        </a>
                    {/if}
                    {#if socialMediaUrls.youtube}
                        <a class="m-2" href="{socialMediaUrls.youtube}" target="_blank" rel="noopener noreferrer">
                            <YoutubeIcon />
                        </a>
                    {/if}
                    {#if socialMediaUrls.telegram}
                        <a class="m-2" href="{socialMediaUrls.telegram}" target="_blank" rel="noopener noreferrer">
                            <TelegramIcon />
                        </a>
                    {/if}
                    {#if socialMediaUrls.reddit}
                        <a class="m-2" href="{socialMediaUrls.reddit}" target="_blank" rel="noopener noreferrer">
                            <!-- RedditIcon here if you have one -->
                        </a>
                    {/if}
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <iframe
                class="{blendMode}"
                src="https://status.buymynudes.com/badge?theme={theme}"
                title="BMN status"
                width="250"
                height="30"
                scrolling="no"
            >
            </iframe>
        </div>
        <div class="flex items-center justify-center">
            <p class="center m-0 text-center text-xs leading-5 text-gray-500 dark:text-gray-300">
                &copy; {currentYear} BuyMyNudes. All rights reserved.
            </p>
        </div>
    </div>
</footer>

<style>
    .mix-blend-multiply {
        mix-blend-mode: multiply;
    }
</style>
